<!-- 컨텐츠 리스트 -->
<template>
  <v-container
    id="contentsList"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              컨텐츠 리스트
            </h1>
          </template>
          <v-card-text>
            <!--상단-->
            <v-row justify="space-between" align="center" class="ma-0">
              <!--on/off 버튼-->
              <div>
                <v-btn
                        :disabled="vModel.contentSelectedBtn[0]"
                        class="ma-1"
                        color="warning"
                        @click="contentExposure(true)"
                        small>
                  ON
                </v-btn>
                <v-btn
                        :disabled="vModel.contentSelectedBtn[1]"
                        class="ma-1"
                        color="warning"
                        @click="contentExposure(false)"
                        small >
                  OFF
                </v-btn>
              </div>
              <!--검색 설정-->
              <v-col cols="8" class="pa-0">
                <v-row>
                  <v-col>
                   <v-select
                          :items="config.selectOptions"
                          v-model="vModel.select"
                          label="상태"
                          color="secondary"
                          @change="submitKeyword"/>
                  </v-col>
                  <v-col>
                    <v-select
                            :items="config.category1"
                            v-model="vModel.type"
                            label="종류"
                            color="secondary"
                            @change="changeType" />
                  </v-col>
                  <v-col>
                    <v-select
                            :items="config.category2"
                            v-model="vModel.category"
                            label="카테고리"
                            color="secondary"
                            :disabled="!vModel.type"
                            @change="submitKeyword"/>
                  </v-col>
                  <v-col style="margin-top: 13px;">
                    <form @submit.prevent="submitKeyword">
                      <v-text-field
                              v-model="keyword"
                              name="keyword"
                              append-icon="mdi-magnify"
                              class="ml-auto pt-0"
                              label="제목 검색"
                              color="secondary"
                              hide-details
                              single-line />
                    </form>
                  </v-col>
                  <v-col v-if="this.userRole !== 'partner'">
                    <v-select
                            :items="config.partnersOptions"
                            v-model="vModel.partners"
                            label="CP명"
                            color="secondary"
                            @change="submitKeyword"/>
                  </v-col>
                  <v-col>
                    <v-select
                            :items="config.alignOptions"
                            v-model="vModel.align"
                            label="정렬"
                            color="secondary"
                            @change="submitKeyword"/>
                  </v-col>
                  <v-col>
                    <v-select
                            :items="config.perOptions"
                            v-model="vModel.per"
                            color="secondary"
                            @change="submitKeyword"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
            <!--컨텐츠 리스트 영역-->
            <template v-if="resultContents">
              <v-data-table
                    v-model="contentSelectedData"
                    :headers="config.headers"
                    :items="resultContents.contents"
                    :items-per-page="Number(resultContents.meta.perPage)"
                    item-key="key"
                    no-data-text="컨텐츠가 없습니다."
                    hide-default-footer
                    show-select>
                <template v-slot:item="{ item, isSelected, index }">
                    <tr :class="tableRowClass(item, isSelected)">
                        <td>
                            <v-simple-checkbox
                                    :ripple="false"
                                    :value="isSelected"
                                    @input="toggleRow(item)"
                                    class="v-data-table__checkbox"
                                    hide-details />
                        </td>
                        <td class="text-center">
                            {{resultContents.meta.total - index}}
                        </td>
                        <td class="text-center">
                            <template v-if="item.isOn">ON</template>
                            <template v-else>OFF</template>
                        </td>
                        <td class="text-center">{{item.type}}</td>
                        <td class="text-center">{{item.category}}</td>
                        <td class="text-center">
                            <img :src="getThumbImageUrl(item.thumbnail_url, item.url)" style="max-height:100px;max-width:100px;"/>
                        </td>
                        <td>
                            <p class="text-left ma-0 listTitle"
                               @click="tableRowClick(item)">{{ convertHtml(item.title) }}</p>
                            <p v-if="item.url" class="mt-2">
                              {{ decodeURI(item.url) }}
                              <a :href="item.url" target="_blank" title="원문 보기">
                                <i aria-hidden="true" class="v-icon notranslate mdi mdi-link-variant"></i>
                              </a>
                            </p>
                        </td>
                        <td class="text-center">{{item.partner.name}}</td>
                        <td class="text-center">
                          {{ toDateFormat(item.created) }}
                        </td>
                        <!-- td class="text-center">{{ item.assucationCount }}</td -->
                        <td class="text-center">
                            <v-btn
                                    v-if="!item.published_at"
                                    small
                                    color="warning"
                                    @click="putPublish(item)">
                                송출하기
                            </v-btn>
                            <template v-else>
                                송출 완료
                            </template>
                        </td>
                    </tr>
                </template>
              </v-data-table>
              <v-pagination
                    v-model="vModel.page"
                    :length="resultContents.meta.lastPage"
                    :total-visible="10"
                    @input="paginationClick"
                    circle
                    class="justify-center mt-5"
                    next-icon="mdi-menu-right"
                    prev-icon="mdi-menu-left"/>
          </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        exposure: null,
        publish: null,
        list: null,
        category: null,
        category2: {},
      },
      keyword: null,
      contentSelectedData: [],
      vModel: {
        select: 'all',
        type: '',
        category: '',
        partners: '',
        align: 0,
        per: 20,
        page: 1,
        orderby: 'created',
        direction: 'desc',
        where: null,
        contentSelected: [],
        contentSelectedIsOn: false,
        contentSelectedBtn: [ true, true ]
      },
      config: {
        selectOptions: [
          { value: 'all', text: '전체' },
          { value: 'on', text: 'ON' },
          { value: 'off', text: 'OFF' }
        ],
        category1: [],
        category2: [],
        partnersOptions: [],
        alignOptions: [
          { value: 0, text: '최신순' },
          { value: 1, text: '오래된순' }
          // { value: 2, text: '신고건수 많은순' },
          // { value: 3, text: '신고건수 적은순' },
        ],
        perOptions: [20, 50, 100, 200, 500, 1000],
        headers: [
          { text: 'No', value: 'no', align: 'center' },
          { text: '상태', value: 'isOn', align: 'center' },
          { text: '종류', value: 'type', align: 'center' },
          { text: '카테고리', value: 'category', align: 'center' },
          { text: '썸네일', value: 'thumbnail', align: 'center' },
          { text: '제목', value: 'title', align: 'center' },
          { text: 'CP명', value: 'partner.name', align: 'center' },
          { text: '등록일', value: 'created', align: 'center' },
          // { text: '신고건수', value: 'assucationCount', align: 'center' },
          { text: '송출', value: 'btn', align: 'center' },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 리스트 체크박스 클릭시 */
    toggleRow (item) {
      if (this.contentSelectedData.includes(item)) {
        this.contentSelectedData = this.contentSelectedData.filter(v => v !== item)
      } else {
        this.contentSelectedData.push(item)
      }
    },
    /* 리스트 체크박스 클릭시 tr class 설정 */
    tableRowClass (item, isSelected) {
      let klasses = []
      if (isSelected) klasses.push('v-data-table__selected') // checkbox が選択されていたら tr に v-data-table__selected クラスを追加する

      if (!item.isOn) {
        klasses.push('grey')
      }
      return klasses.join(' ')
    },
    /* 페이지 로딩시 */
    refresh () {
      let send = {}
      if (this.$route.query.page) {
        send.page = this.$route.query.page
        this.vModel.page = Number(this.$route.query.page)
      }
      if (this.$route.query.per) {
        send.per = this.$route.query.per
        this.vModel.per = Number(this.$route.query.per)
      }
      if (this.$route.query.on) {
        send.on = this.$route.query.on
        this.vModel.select = this.$route.query.on
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
        this.keyword = this.$route.query.keyword
      }
      if (this.$route.query.partner) {
        send.partner = this.$route.query.partner
        this.vModel.partners = this.$route.query.partner
      }
      if (this.$route.query.type) {
        send.type = Number(this.$route.query.type)
        this.vModel.type = Number(this.$route.query.type)
      }
      if (this.$route.query.category) {
        send.category = Number(this.$route.query.category)
        this.vModel.category = Number(this.$route.query.category)
      }
      if (this.$route.query.align) {
        send.align = this.$route.query.align
        this.vModel.align = Number(this.$route.query.align)
      }
      this.$router.replace({ name: 'contentsList', query: send })
      this.contentsList()
      this.getCategories()
      this.getPartners()
    },
    /* 컨텐츠 리스트 클릭시 */
    tableRowClick (val) {
      this.$router.push({ name: 'contentsView', params: { contents_id: val.key } })
    },
    /* 페이지네이션 클릭시 */
    paginationClick (page) {
      this.contentSelectedData = []
      this.vModel.page = page
      let send = {
        page: page
      }
      if (this.$route.query.per) {
        send.per = this.$route.query.per
        this.vModel.per = Number(this.$route.query.per)
      }
      if (this.$route.query.on) {
        send.on = this.$route.query.on
        this.vModel.select = this.$route.query.on
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
        this.keyword = this.$route.query.keyword
      }
      if (this.$route.query.partner) {
        send.partner = this.$route.query.partner
        this.vModel.partners = this.$route.query.partner
      }
      if (this.$route.query.type) {
        send.type = Number(this.$route.query.type)
        this.vModel.type = Number(this.$route.query.type)
      }
      if (this.$route.query.category) {
        send.category = Number(this.$route.query.category)
        this.vModel.category = Number(this.$route.query.category)
      }
      if (this.$route.query.align) {
        send.align = this.$route.query.align
        this.vModel.align = Number(this.$route.query.align)
      }
      this.$router.replace({ name: 'contentsList', query: send })
      this.contentsList()
    },
    /* 검색시 */
    submitKeyword () {
      let send = {
        page: 1,
        per: this.vModel.per,
        on: this.vModel.select
      }
      if (this.keyword) {
        send.keyword = this.keyword
      }
      if (this.vModel.partners) {
        send.partner = this.vModel.partners
      }
      if (this.vModel.type) {
        send.type = this.vModel.type
      }
      if (this.vModel.category) {
        send.category = this.vModel.category
      }
      if (this.vModel.align) {
        send.align = this.vModel.align
      }
      this.$router.replace({ name: 'contentsList', query: send })
      this.vModel.page = 1
      this.contentsList()
    },
    /* on/off 버튼 선택시 */
    contentExposure (val) {
      let text
      if (val) {
        // on
        text = '게재'
      } else {
        // off
        text = '차단'
      }
      if (confirm('컨텐츠를 ' + text + ' 하시겠습니까?')) {
        let data = this.contentSelectedData.map(function ($value) {
          return $value.key
        })
        for (let idx in data) {
          this.exposure(data[idx], idx)
        }
      }
    },
    /* 종류 선택시 */
    changeType (val) {
      this.vModel.category = null
      if (this.vModel.type !== '') {
        this.config.category2 = this.response.category2[Number(val)]
        this.config.category2.unshift({ value: '', text: '전체' })
      }
      this.submitKeyword()
    },
    /* 카테고리 가져오기 */
    getCategories () {
      let send = {
        is_enabled: 1,
      }
      this.xhttp({
        url: '/categories',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.category = response.data.data.categories
          this.config.category1 = this.response.category.map(function ($value) {
            return { value: $value.id, text: $value.name }
          })
          this.config.category1 = this.config.category1.filter(function ($value) {
            return $value.value !== 'Toon'
          })
          this.config.category1.unshift({ value: '', text: '전체' })
          for (let i in this.response.category) {
            this.response.category2[this.response.category[i].id] = this.response.category[i].children.map(function ($value) {
              return { value: $value.id, text: `${$value.name} ( ${$value.contents_count}  )` }
            })
          }
          if (this.$route.query.type) {
            this.config.category2 = this.response.category2[Number(this.$route.query.type)]
            this.config.category2.unshift({ value: '', text: '전체' })
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp 리스트 가져오기 */
    getPartners () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'contract_status_priority',
        direction: 'asc'
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners
          this.response.partner = this.response.partner.filter(function ($value) {
            return $value.totalContents > 0
          })
          this.config.partnersOptions = this.response.partner.map(function ($value) {
            return { value: $value.key, text: `${$value.name} ( ${$value.totalContents} )` }
          })
          this.config.partnersOptions.unshift({ value: '', text: '전체' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 컨텐츠 송출하기 */
    putPublish (val) {
      this.xhttp({
        url: '/contents/' + val.key + '/publish',
        method: 'put',
        data: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.publish = response.data.data
          this.refresh()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 컨텐츠 체크박스 선택시 설정 */
    contentSelectedFn (val) {
      if (val.length === 1 && !this.vModel.contentSelectedIsOn) {
        // 하나만
        if (val[0].isOn) {
          // 첫번째 클릭한게 on
          this.vModel.contentSelectedBtn = [true, false]
        } else {
          // 첫번째 클릭한게 off
          this.vModel.contentSelectedBtn = [false, true]
        }
        this.vModel.contentSelectedIsOn = true
      } if (val.length === 0) {
        this.vModel.contentSelectedBtn = [true, true]
        this.vModel.contentSelectedIsOn = false
      } else if (val.length > 1) {
        // 첫번째 클릭한 값
        let bool = this.vModel.contentSelectedBtn[0]
        if (bool) {
          // 첫번째 클릭한 값 on
          let a = val[val.length - 1].isOn
          if (!a) {
            this.contentSelectedData = val.filter(function ($value) {
              return $value.isOn === true
            })
          }
        } else {
          // 첫번째 클릭한 값 off
          let a = val[val.length - 1].isOn
          if (a) {
            this.contentSelectedData = val.filter(function ($value) {
              return $value.isOn === false
            })
          }
        }
      }
    },
    /* on/off 설정 */
    exposure (id, idx) {
      this.xhttp({
        url: '/contents/' + id + '/exposure',
        method: 'put',
        data: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.exposure = response.data.data
          let num = this.contentSelectedData[idx].num
          this.response.list.contents[num].isOn = this.response.exposure.contents.isOn
          if (this.contentSelectedData.length - 1 === num) {
            this.contentSelectedData = []
            this.response.list = null
            this.contentsList()
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 컨텐츠 리스트 가져오기 */
    contentsList () {
      this.response.list = null
      this.contentSelectedData = []
      let send = {
        per: this.vModel.per,
        page: this.vModel.page,
        orderby: this.vModel.orderby,
        direction: this.vModel.direction,
        on: 'all'
      }
      if (this.$route.query.page) {
        send.page = this.$route.query.page
        this.vModel.page = Number(this.$route.query.page)
      }
      if (this.$route.query.per) {
        send.per = this.$route.query.per
        this.vModel.per = Number(this.$route.query.per)
      }
      if (this.$route.query.on) {
        send.on = this.$route.query.on
        this.vModel.select = this.$route.query.on
      }
      if (this.$route.query.keyword) {
        send.keyword = this.$route.query.keyword
        send.where = 'title'
      }
      if (this.$route.query.partner) {
        send.partner = this.$route.query.partner
      }
      if (this.$route.query.type) {
        send.type = this.$route.query.type
      }
      if (this.$route.query.category) {
        send.category = this.$route.query.category
      }
      if (this.$route.query.orderby) {
        send.orderby = this.$route.query.orderby
      }
      if (this.$route.query.direction) {
        send.direction = this.$route.query.direction
      }
      if (this.$route.query.align === 0) {
        send.orderby = 'created'
        send.direction = 'desc'
      } else if (this.$route.query.align === 1) {
        send.orderby = 'created'
        send.direction = 'asc'
      }
      this.xhttp({
        url: '/contents',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.list = response.data.data
          for (let i = 0; i < this.response.list.contents.length; i++) {
            this.response.list.contents[i].num = i
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* 등록일시 설정 */
    toDateFormat (targetDateTime) {
      let targetDate = targetDateTime.slice(0, 10)
      if (targetDate === this.$moment(new Date()).format('YYYY-MM-DD')) {
        return targetDateTime.slice(11)
      }
      return targetDate
    },
    /**
     * 완전한 썸네일 URL을 반환
     * => thumbnailUrl에 HTTP/S SCHEME이 누락된 경우 기사URL에서 추출해서 첨부
     *
     * @param thumbnailUrl
     * @param articleUrl
     * @returns {string|*}
     */
    getThumbImageUrl (thumbnailUrl, articleUrl) {
      thumbnailUrl = thumbnailUrl.trim()
      if (thumbnailUrl.substring(0, 2) === '//') {
        let scheme = 'https'
        if (articleUrl) {
          scheme = articleUrl.split('://')[0]
        }
        return scheme + ':' + thumbnailUrl
      }

      return thumbnailUrl
    }
  },
  computed: {
    resultContents () {
      if (this.response.list) {
        return this.response.list
      } else {
        return false
      }
    }
  },
  watch: {
    keyword: function (val) {
      if (val === '') {
        this.submitKeyword()
      }
    },
    contentSelectedData: function (val) {
      if (val) {
        this.contentSelectedFn(val)
      }
    }
  }
}
</script>

<style>
    #contentsList .listTitle:hover {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
